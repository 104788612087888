import React from 'react'
import { styled } from '@mui/material'
import Plx from 'react-plx'

const StyledPlx = styled(Plx)({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
})

const BackgroundPlx = ({ ...props }) => {
  return <StyledPlx {...props} />
}

export default BackgroundPlx
