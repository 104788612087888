import React, { memo } from 'react'
import { styled } from '@mui/material'

interface MarkdownProps {
  html: string
}

const Text = styled('div')(({ theme }) => ({
  h1: {
    ...theme.typography.h1,
  },
  h2: {
    ...theme.typography.h2,
  },
  h3: {
    ...theme.typography.h3,
  },
  h4: {
    ...theme.typography.h4,
  },
  h5: {
    ...theme.typography.h5,
  },
  p: {
    '&:not(:first-of-type)': {
      margin: '3rem 0',
    },
  },
  a: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['color']),
    textDecoration: 'none',
    ['&:hover']: {
      color: theme.palette.text.primary,
    },
  },
  ol: {
    padding: 0,
    counterReset: 'item',
    listStyle: 'none',
    li: {
      display: 'block',
      marginLeft: '3rem',
      position: 'relative',
      strong: {
        fontFamily: theme.typography.fonts.serif,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
      },
      '&:before': {
        content: 'counter(item) ". "',
        counterIncrement: 'item',
        width: '3rem',
        position: 'absolute',
        transform: 'translateX(-100%)',
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fonts.serif,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  ul: {
    padding: 0,
    listStyle: 'none',
    li: {
      position: 'relative',
      paddingLeft: '2rem',
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      },
      p: {
        margin: 0,
      },
      '&:before': {
        position: 'absolute',
        top: '50%',
        left: 0,
        content: '""',
        display: 'block',
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))

const Markdown = ({ html, ...props }: MarkdownProps) => (
  <Text
    {...props}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

export default memo(Markdown)
