import React, { memo } from 'react'
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import loadable from '@loadable/component'

import Markdown from '@atoms/text/Markdown'
const ContentLeadText = loadable(() => import('./components/ContentLeadText'))
const ContentQuote = loadable(() => import('./components/ContentQuote'))
const ContentImage = loadable(() => import('./components/ContentImage'))
const ContentYouTubeVideo = loadable(
  () => import('./components/ContentYouTubeVideo')
)
const ContentProjectSummary = loadable(
  () => import('@molecules/sections/components/ContentSectionRecentProjects')
)
const SectionTextImage = loadable(
  () => import('@molecules/sections/components/ContentSectionTextImage')
)

type Component =
  | Queries.DatoCmsComponentHeading
  | Queries.DatoCmsComponentText
  | Queries.DatoCmsComponentLeadText
  | Queries.DatoCmsComponentQuote
  | Queries.DatoCmsComponentImage
  | Queries.DatoCmsComponentYoutubeVideo
  | Queries.DatoCmsComponentProjectsSummary

export interface ContentComponentsProps {
  components: Component[]
  withMarginBottom?: boolean
}

const ContentComponents = ({
  components,
  withMarginBottom,
}: ContentComponentsProps) => {
  const theme = useTheme()
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'))

  let sx = {
    sx: {
      marginTop: { xs: '40px', sm: '42px', md: '44px', lg: '47px', xl: '50px' },
      marginBottom: withMarginBottom
        ? {
            xs: '40px',
            sm: '42px',
            md: '44px',
            lg: '47px',
            xl: '50px',
          }
        : {},
    },
  }
  return (
    <>
      {components.map((item: any, index: number) => {
        if (item && item.model && item.model.apiKey) {
          switch (item.model.apiKey) {
            case 'component_youtube_video': {
              const component = item as Queries.DatoCmsComponentYoutubeVideo
              return (
                <Container
                  maxWidth={xlDown ? 'md' : 'lg'}
                  key={component.originalId}
                  {...sx}
                >
                  <ContentYouTubeVideo
                    youTubeUrl={component.videoUrl.url}
                    caption={component.caption}
                  />
                </Container>
              )
            }
            case 'component_lead_text': {
              const component = item as Queries.DatoCmsComponentLeadText
              return (
                <Container
                  maxWidth={xlDown ? 'sm' : 'md'}
                  key={component.originalId}
                  {...sx}
                >
                  <ContentLeadText text={component.text} />
                </Container>
              )
            }
            case 'component_quote': {
              const component = item as Queries.DatoCmsComponentQuote
              return (
                <Container
                  maxWidth={xlDown ? 'sm' : 'md'}
                  key={component.originalId}
                  {...sx}
                >
                  <ContentQuote
                    quote={component.quote}
                    name={component.name}
                    location={component.location}
                  />
                </Container>
              )
            }
            case 'component_text': {
              const component = item as Queries.DatoCmsComponentText
              return (
                <Container
                  maxWidth={xlDown ? 'sm' : 'md'}
                  key={component.originalId}
                  {...sx}
                >
                  <Markdown
                    html={component.textNode.childMarkdownRemark.html}
                  />
                </Container>
              )
            }
            case 'component_image': {
              const component = item as Queries.DatoCmsComponentImage
              return (
                <Container
                  maxWidth={xlDown ? 'md' : 'lg'}
                  key={component.originalId}
                  {...sx}
                >
                  <ContentImage
                    image={component.image.gatsbyImageData}
                    caption={component.image.title}
                  />
                </Container>
              )
            }
            case 'component_image_text': {
              const component = item as Queries.DatoCmsComponentImageText
              return (
                <Container
                  maxWidth={xlDown ? 'md' : 'lg'}
                  key={component.originalId}
                  {...sx}
                >
                  <SectionTextImage
                    isContentComponent
                    keyId={component.originalId}
                    image={component.image.gatsbyImageData}
                    mainHeading={
                      component.mainHeadingNode.childMarkdownRemark.html
                    }
                    subtitle={component.smallHeading}
                    text={component.textNode.childMarkdownRemark.html}
                    button={component.button[0]}
                    imagePlacement={component.imagePlacement}
                    textContainerProps={{
                      maxWidth: xlDown ? 'sm' : 'md',
                      sx: { alignSelf: 'center' },
                    }}
                  />
                </Container>
              )
            }
            case 'component_heading': {
              const component = item as Queries.DatoCmsComponentHeading
              return (
                <Container
                  maxWidth={xlDown ? 'sm' : 'md'}
                  key={component.originalId}
                  {...sx}
                >
                  <Typography variant="h5" component="h2">
                    {component.heading}
                  </Typography>
                </Container>
              )
            }
            case 'component_projects_summary': {
              const component = item as Queries.DatoCmsComponentProjectsSummary
              return (
                <Box pt={5}>
                  <ContentProjectSummary />
                </Box>
              )
            }

            default: {
              return <></>
            }
          }
        }
      })}
    </>
  )
}

export default memo(ContentComponents)
