// npm
import React from 'react'
import { styled } from '@mui/material'

const StyledTint = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
})

const Tint = () => {
  return <StyledTint />
}

export default Tint
