import React, { memo } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import {
  Box,
  Container,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material'

import Flex from '@atoms/layout/Flex'
import BackgroundPlx from '@atoms/plx/BackgroundPlx'
import Tint from '@atoms/media/Tint'
import Subtitle from '@molecules/headings/Subtitle'

export interface PageTitleHeroProps {
  smallHeading: string
  mainHeading: string
  text?: string
  mainHeadingProps?: TypographyProps
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  children?: React.ReactNode
}

const OuterWrapper = styled(Flex)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  color: 'white',
  textAlign: 'center',
  marginTop: '86px', // Header compact height
  [theme.breakpoints.up('lg')]: {
    marginTop: '100px',
  },
  ['&, .fixed-height']: {
    minHeight: '500px',
  },
  [theme.breakpoints.up('md')]: {
    ['&, .fixed-height']: {
      minHeight: '600px',
    },
  },
  [theme.breakpoints.up('md')]: {
    ['&, .fixed-height']: {
      minHeight: '700px',
    },
  },
}))

const BgImage = styled(GatsbyImage)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '166.6666666666667%',
  },
}))

const PageTitleHero = ({
  mainHeading,
  mainHeadingProps,
  smallHeading,
  imagePortrait,
  imageLandscape,
  text,
  children,
}: PageTitleHeroProps) => {
  const theme = useTheme()
  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <OuterWrapper center>
      <Box bgcolor="primary.main" height="100%" width="100%">
        <BackgroundPlx parallaxData={parallaxData}>
          <BgImage image={images} alt="" />
        </BackgroundPlx>
        <Tint />
        <Container
          maxWidth="lg"
          className="fixed-height"
          sx={{ height: '100%', position: 'relative' }}
        >
          <Flex center full className="fixed-height">
            <Box textAlign={{ xs: 'center' }}>
              {smallHeading && (
                <Subtitle
                  typographyProps={{ component: 'h1' }}
                  text={smallHeading}
                  borderType="bottom"
                />
              )}
              <Typography
                variant="h1"
                sx={{ display: 'block' }}
                {...mainHeadingProps}
              >
                {mainHeading}
              </Typography>
              {text && (
                <Container maxWidth="md" sx={{ ml: 'auto' }}>
                  <Box mt={3} fontSize="2rem">
                    {text}
                  </Box>
                </Container>
              )}
              {children}
            </Box>
          </Flex>
        </Container>
      </Box>
    </OuterWrapper>
  )
}

export default memo(PageTitleHero)
