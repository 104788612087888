// npm
import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Box, Container } from '@mui/material'

// components
import Card from '@molecules/cards/Card'
import ContentComponents from '@molecules/contentComponents/ContentComponents'
import Layout from '@organisms/global/Layout'
import PageTitleHero from '@organisms/hero/PageTitleHero'
import MainHeading from '@molecules/headings/MainHeading'

interface Props {
  data: Queries.KeywordDetailQuery
}

const KeywordDetail = ({ data }: Props) => {
  const d = data.datoCmsKeyword

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      headerProps={{
        sizeVariant: 'compact',
        themeVariant: 'dark',
        forceSolidNav: true,
      }}
    >
      {d.shortTitle && d.category[0] && d.imagePortrait && d.imageLandscape && (
        <PageTitleHero
          mainHeading={d.longTitle ? d.longTitle : d.shortTitle}
          smallHeading={d.category[0].name}
          text={d.heroText}
          imagePortrait={d.imagePortrait.gatsbyImageData}
          imageLandscape={d.imageLandscape.gatsbyImageData}
        />
      )}

      <Box py={6}>
        {d.leadText && (
          <ContentComponents components={d.leadText} withMarginBottom />
        )}
        {d.contentComponents && (
          <ContentComponents components={d.contentComponents} />
        )}
      </Box>

      {data.allDatoCmsBlogPost.edges.length > 0 && (
        <Box
          bgcolor="primary.light"
          position="relative"
          overflow="hidden"
          py={{ xs: 6 }}
        >
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <MainHeading
              {...{
                typographyProps: {
                  variant: 'h2',
                  color: 'inherit',
                  textAlign: 'center',
                  lineHeight: '1',
                  mb: 0,
                  mt: 2,
                },
                lineBreaksProps: {
                  keyId: `heading=${d.originalId}`,
                  text: 'Associated Blog Posts',
                  breakpoint: 'xs',
                },
              }}
            />
          </Container>

          <Container maxWidth="xl" sx={{ mt: 8 }}>
            {data.allDatoCmsBlogPost.edges.map((item: any, index: number) => {
              const post: Queries.DatoCmsBlogPost = item.node

              return <Card key={post.originalId} data={post} highlight />
            })}
          </Container>
        </Box>
      )}
    </Layout>
  )
}

export default memo(KeywordDetail)

export const pageQuery = graphql`
  query KeywordDetail($originalId: String!) {
    datoCmsKeyword(originalId: { eq: $originalId }) {
      originalId
      category {
        name
      }
      heroText
      leadText {
        ...componentLeadText
      }
      contentComponents {
        ... on DatoCmsComponentText {
          ...componentText
        }
        ... on DatoCmsComponentHeading {
          ...componentHeading
        }
        ... on DatoCmsComponentQuote {
          ...componentQuote
        }
        ... on DatoCmsComponentLeadText {
          ...componentLeadText
        }
        ... on DatoCmsComponentYoutubeVideo {
          ...componentYoutubeVideo
        }
        ... on DatoCmsComponentImage {
          ...componentImage
        }
      }
      imageLandscape: heroImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "16:5", fit: "crop", crop: "focalpoint" }
        )
      }
      imagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:5", fit: "crop", crop: "focalpoint" }
        )
      }
      shortTitle
      longTitle
    }
    allDatoCmsBlogPost(
      filter: {
        keywordTags: { elemMatch: { originalId: { eq: $originalId } } }
      }
    ) {
      edges {
        node {
          ...cardBlogPost
        }
      }
    }
  }
`
